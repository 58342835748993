/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import axios, { type AxiosInstance, type AxiosRequestConfig, type AxiosResponse, type CreateAxiosDefaults } from 'axios';

import { isValidationError } from './errors/is-validation-error';
import { normalizeValidationErrors } from './lib/normalize-validation-errors';
import type { ApiResponse, BadRequestResponse, HttpResponse, RequestOptions } from './types';
import { stringify } from 'qs'

import toastService from 'ui/services/toast/toast.service';
import { omitBy } from 'lodash-es';

export class Http {
  axios: AxiosInstance;

  constructor(config: CreateAxiosDefaults) {
    this.axios = axios.create({
      baseURL: '/',
      validateStatus: (status: number) => status < 500,
      withCredentials: true, // use for vid cookie to root domain
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      ...config,
    });
  }

  public get<T = any>(url: string, config?: AxiosRequestConfig, requestOptions?: RequestOptions): HttpResponse<T> {
    return this.axios.get(url, {
      ...config,
      paramsSerializer(params) {
        const normalizedParams = omitBy(params, (value) => {
          return value === undefined || value === null || value === '' || Array.isArray(value) && !value.length;
        })
        return stringify(normalizedParams, { arrayFormat: 'comma' });
      }
    }).then((res) => this.onFulfilled(res, requestOptions), this.onReject);
  }

  public post<T = any>(url: string, data?: unknown, requestOptions?: RequestOptions): HttpResponse<T> {
    return this.axios.post(url, data).then((res) => this.onFulfilled(res, requestOptions), this.onReject);
  }

  public patch<T = any>(url: string, data?: unknown, requestOptions?: RequestOptions): HttpResponse<T> {
    return this.axios.patch(url, data).then((res) => this.onFulfilled(res, requestOptions), this.onReject);
  }

  public put<T = any>(url: string, data?: unknown, requestOptions?: RequestOptions): HttpResponse<T> {
    return this.axios.put(url, data).then((res) => this.onFulfilled(res, requestOptions), this.onReject);
  }

  public delete<T = unknown>(
    url: string,
    config?: AxiosRequestConfig,
    requestOptions?: RequestOptions,
  ): HttpResponse<T> {
    return this.axios.delete(url, config).then((res) => this.onFulfilled(res, requestOptions), this.onReject);
  }

  private onFulfilled = (axiosResponse: AxiosResponse<any>, requestOptions?: RequestOptions) => {
    const { data, status } = axiosResponse;

    const res: ApiResponse<any> = {
      status,
      response: data,
      success: status >= 200 && status < 400,
    };

    if (isValidationError(data as BadRequestResponse)) {
      res.validationErrors = normalizeValidationErrors(data as BadRequestResponse);
    }

    // TODO: handle other errors
    else if (status >= 400) {
      if (requestOptions?.notify !== false) {
        console.error(data);
        toastService.error(data.message);
      }
    }

    return res;
  };

  private onReject = (error: Error) => {
    throw error;
  };
}
