import type { BadRequestResponse, ValidationErrors } from '../types';

export function normalizeValidationErrors(res: BadRequestResponse): ValidationErrors {
  return res.message.reduce((acc, message) => {
    if (message.text) {
      acc[message.property] = message.text.join(', ');
    } else if (Array.isArray(message)) {
      acc[message.property] = message[0];
    }
    return acc;
  }, {} as ValidationErrors);
}
export function normalizeValidationErrorsForFelte(res: BadRequestResponse): ValidationErrors {
  if (typeof res.message === 'string') {
    return {
      FormErrorMessage: res.message,
    };
  }
  return res.message.reduce((acc, message) => {
    // use first error for avoid redundant message. example "unitId should not be empty. unitId must be a UUID"
    acc[message.property] = message.text[0];
    return acc;
  }, {});
}
